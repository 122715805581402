import React from "react";
import { API } from "aws-amplify";
import { Table, Badge, Image } from "react-bootstrap";
import { withRouter } from "react-router";
import Moment from "react-moment";
import JSONPretty from 'react-json-pretty';
import SFNUtils from "../libs/SFNUtils";
import { queryString } from "../libs/Utilitites";
import "./Detail.css";

class Detail extends React.Component {
  constructor(props) {
    super(props);
    this.state = { sfnData: {}, sfnExec: {}, sfnDiagram: "", isReady: false };
  }

  componentDidMount = async () => {
    this.setState({ isReady: false });
    const sfnId = queryString("sfnId");
    const execId = queryString("execId");

    var sfnData = {};
    // await API.get("sfn", "/sfn/" + this.props.location.state.sfnId, {})
    await API.get("sfn", "/sfn/" + sfnId, {})
      .then((data) => {
        sfnData = data;
      })
      .catch((err) => console.log("SFN: " + err.message));

    var sfnExec = {};
    // await API.get("sfn", "/exec/" + this.props.location.state.execId, {})
    await API.get("sfn", "/exec/" + execId, {})
      .then((data) => {
        sfnExec = data;
      })
      .catch((err) => console.log("EXEC: " + err.message));

    var execEvents = [];
    var nextToken = "";
    do {
      // let queryString = "/exec/" + this.props.location.state.execId + "/detail";
      let queryString = "/exec/" + execId + "/detail";
      if (nextToken.length > 0) {
        queryString = queryString + "?nextToken=" + encodeURIComponent(nextToken);
      }
      await API.get("sfn", queryString, {})
        .then((data) => {
          execEvents = execEvents.concat(data.events);
          nextToken = "nextToken" in data ? data.nextToken : "";
        })
        .catch((err) => {
          nextToken = "";
          console.log("EXEC: " + err.message)
        });
    } while (nextToken.length > 0);

    var sfnExecDef = {};
    // await API.get("sfn", "/exec/" + this.props.location.state.execId + "/sfn", {})
    await API.get("sfn", "/exec/" + execId + "/sfn", {})
      .then((data) => {
        sfnExecDef = data;
      })
      .catch((err) => console.log("EXEC: " + err.message));
    if (sfnExecDef.length === 0) {
      sfnExecDef = sfnData;
    }

    try {
      this.setState({
        sfnDiagram: new SFNUtils(true).getSfnGraph(
          JSON.parse(sfnExecDef.definition),
          execEvents
        ),
      });
    } catch (pError) {
      console.error(pError);
    }

    // SE OBTIENE EL OUTPUT CUANDO EL PROCESO ACABA EN ERROR
    if (sfnExec.status === 'FAILED') {
      for (let i in execEvents) {
        let name = ''
        if (execEvents[i].stateExitedEventDetails) {
          name = execEvents[i].stateExitedEventDetails.name
        }
        // console.log(name)
        if (name === 'Error task') {
          sfnExec.output = execEvents[i].stateExitedEventDetails.output
        }
      }
    }

    this.setState({ sfnData: sfnData, sfnExec: sfnExec, isReady: true });
  };

  getExecScheduled = (execName) => {
    if (String(execName).includes("_")) {
      return "YES"
    } else {
      return "NO"
    }
  }

  getSfnBadgeStatus = (status) => {
    let variant = "";
    if (status === "SUCCEEDED") {
      variant = "success";
    } else if (status === "RUNNING") {
      variant = "primary";
    } else if (status === "NOT EXECUTED") {
      variant = "warning";
    } else {
      variant = "danger";
    }
    return (
      <Badge pill variant={variant}>
        {status}
      </Badge>
    );
  };

  render() {
    return (
      <div>
        <div className="Detail">
          <h2>Execution detail</h2>
          {this.state.isReady &&
            <Table width="100%" height="auto" striped bordered hover size="sm">
              <thead>
                <tr>
                  <th className="align-middle">Workflow</th>
                  <th className="align-middle">Execution ID</th>
                  <th className="align-middle">Start</th>
                  <th className="align-middle">End</th>
                  <th className="align-middle">Scheduled</th>
                  <th className="align-middle">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="align-middle">{this.state.sfnData.name}</td>
                  <td className="align-middle">{this.state.sfnExec.name}</td>
                  <td className="align-middle">
                    <Moment unix format="DD/MM/YYYY HH:mm:ss">
                      {this.state.sfnExec.startDate}
                    </Moment>
                  </td>
                  <td className="align-middle">
                    {this.state.sfnExec.stopDate &&
                    <Moment unix format="DD/MM/YYYY HH:mm:ss">
                      {this.state.sfnExec.stopDate}
                    </Moment>}
                  </td>
                  <td className="align-middle">
                    {this.getExecScheduled(this.state.sfnExec.name)}
                  </td>
                  <td className="align-middle">
                    {this.getSfnBadgeStatus(this.state.sfnExec.status)}
                  </td>
                </tr>
              </tbody>
            </Table>
          }
          {!this.state.isReady && (
            <div className="d-flex justify-content-center">
              <Image src="/Loading_icon.gif"/>
            </div>
          )}
        </div>
        <div className="Message">
          {this.state.isReady &&
            <Table id="input" width="100%" height="auto" striped bordered hover size="sm">
              <thead>
                <tr>
                  <th className="align-middle">Input</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><JSONPretty themeClassName="JsonMessage" id="json-input" data={this.state.sfnExec.input} /></td>
                </tr>
              </tbody>
            </Table>
          }
        </div>
        <div className="Message">
          {this.state.isReady &&
            <Table id="output" width="100%" height="auto" striped bordered hover size="sm">
              <thead>
                <tr>
                  <th className="align-middle">Output</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><JSONPretty themeClassName="JsonMessage" id="json-output" data={this.state.sfnExec.output} /></td>
                </tr>
              </tbody>
            </Table>
          }
        </div>
        <div className="Graph" dangerouslySetInnerHTML={{ __html: this.state.sfnDiagram }} />
      </div>
    );
  }
}

export default withRouter(Detail);
