import React from "react";
import { API } from "aws-amplify";
import { Badge, Button, Modal, Form, Spinner, Alert, Image } from "react-bootstrap";
import cronstrue from "cronstrue/i18n";
import moment from "moment";
import SFNUtils from "../libs/SFNUtils";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";
import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { AppContext } from "../libs/contextLib";
import "./Workflows.css";

class Workflows extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sfnList: [],
      showDiagram: false,
      sfnDiagram: { name: "" },
      diagram: "",
      showStatistics: false,
      sfnStatistics: { name: "" },
      sfnStatisticsData: [],
      showExecute: false,
      sfnExecute: { name: "" },
      executeInput: "",
      executing: false,
      executingError: false,
      executingErrorMsg: "",
      tableHeader: [],
      isReady: false,
      isUpdating: false,
    };
  }

  componentDidMount = async () => {
    this.setState({ isReady: false });
    let sfnListRaw = [];
    await API.get("sfn", "/sfn", {})
      .then((data) => {
        sfnListRaw = [...data.stateMachines];
      })
      .catch((err) => console.log("SFN: " + err));
    // SE FILTRAN LOS SFN QUE NO TIENE EL TAG CORRESPONDIENTE
    let sfnList = [];
    for (let i = 0; i < sfnListRaw.length; i++) {
      await API.get("sfn", "/sfn/" + sfnListRaw[i].stateMachineArn + "/tags", {})
        .then((response) => {
          for (let j = 0; j < response.tags.length; j++) {
            let tag = response.tags[j];
            if (tag["key"] == "lgspark_workflow" && tag["value"] == "true") {
              sfnList.push(sfnListRaw[i]);
              break;
            }
          }
        })
        .catch((err) => console.log("TAG: " + err.message));
    }
    sfnListRaw.length = 0;
    // ######################################################
    let nameFilterOptions = {};
    for (let i = 0; i < sfnList.length; i++) {
      nameFilterOptions[sfnList[i].name] = sfnList[i].name;
      // SE OBTIENEN LOS EVENTOS ASOCIADOS
      sfnList[i].rules = [];
      let rules = [];
      await API.get("sfn", "/events/" + sfnList[i].stateMachineArn, {})
        // eslint-disable-next-line no-loop-func
        .then((data) => {
          rules = [...data.RuleNames];
        })
        .catch((err) => console.log("EXEC: " + err));
      if (rules.length > 0) {
        for (let j = 0; j < rules.length; j++) {
          await API.get("sfn", "/events/sfn/" + rules[j], {})
            // eslint-disable-next-line no-loop-func
            .then((data) => {
              sfnList[i].rules.push(data);
              // console.log(data.ScheduleExpression);
            })
            .catch((err) => console.log("EXEC: " + err));
        }
      }
    }
    this.setState({
      sfnList: sfnList,
      nameFilterOptions: nameFilterOptions,
      isReady: true,
    });
    // console.log(sfnList);
  };

  // getFormatter = (num, decimals) =>
  //   num.toLocaleString("en-US", {
  //     minimumFractionDigits: 2,
  //     maximumFractionDigits: 2,
  //   });

  componentDidUpdate = async (prevProps, prevState) => {
    if (prevState.sfnDiagram !== this.state.sfnDiagram  &&  "stateMachineArn" in this.state.sfnDiagram) {
      // SE RECUPERA EL DIAGRAMA
      this.setState({ isUpdating: true });
      await API.get("sfn", "/sfn/" + this.state.sfnDiagram.stateMachineArn, {})
        .then((data) => {
          if (data.definition.length > 0) {
            this.setState({
              diagram: new SFNUtils(false).getSfnGraph(
                JSON.parse(data.definition),
                []
              ),
            });
          }
        })
        .catch((err) => console.log("EXEC: " + err));
      this.setState({ isUpdating: false });
    }
    if (prevState.sfnStatistics !== this.state.sfnStatistics  &&  "stateMachineArn" in this.state.sfnStatistics) {
      // SE OBTIENEN LAS ESTADISTICAS
      this.setState({ isUpdating: true });
      let initDate = new Date();
      initDate.setDate(initDate.getDate() - 14);
      let endDate = this.getIntFromDate(new Date());
      let iterDate = initDate;
      let statusGroup = {};
      while (this.getIntFromDate(iterDate) <= endDate) {
        statusGroup[moment(iterDate).format("DD/MM/YYYY")] = {
          status: {},
          med: 0,
        };
        iterDate.setDate(iterDate.getDate() + 1);
      }
      await API.get("sfn", "/sfn/" + this.state.sfnStatistics.stateMachineArn + "/exec", {})
        .then((response) => {
          for (let index in response.executions) {
            let exec = response.executions[index];
            let axisName = moment.unix(exec.startDate).format("DD/MM/YYYY");
            if (statusGroup[axisName]) {
              statusGroup[axisName].status[exec.status] = statusGroup[axisName]
                .status[exec.status]
                ? statusGroup[axisName].status[exec.status] + 1
                : 1;
              let execDuration = exec.stopDate - exec.startDate;
              statusGroup[axisName].min = statusGroup[axisName].min
                ? Math.min(statusGroup[axisName].min, execDuration)
                : execDuration;
              statusGroup[axisName].max = statusGroup[axisName].max
                ? Math.max(statusGroup[axisName].max, execDuration)
                : execDuration;
              statusGroup[axisName].med =
                statusGroup[axisName].med + execDuration;
            }
          }
          let dataGroup = [];
          for (let item in statusGroup) {
            let data = { name: item, TOTAL: 0, MIN: 0, MED: 0, MAX: 0 };
            for (let status in statusGroup[item].status) {
              data[status] = statusGroup[item].status[status];
              data.TOTAL += data[status];
            }
            if (data.TOTAL > 0) {
              data.MIN = Number(statusGroup[item].min.toFixed(3));
              data.MAX = Number(statusGroup[item].max.toFixed(3));
              data.MED = Number(
                (statusGroup[item].med / data.TOTAL).toFixed(3)
              );
            } else {
              data.MIN = 0;
              data.MAX = 0;
              data.MED = 0;
            }
            dataGroup.push(data);
          }
          this.setState({
            sfnStatisticsData: dataGroup,
          });
        })
        .catch((err) => console.log("EXEC: " + err));
      this.setState({ isUpdating: false });
    }
  };

  getIntFromDate = (date) => {
    return moment(date).format("YYYYMMDD") * 1;
  };

  getEventRules = (item) => {
    // FIXME - MUESTRA EN UTC
    const listRules = item.rules.map((rule, value) => (
      <li key={rule.Name}>
        {cronstrue.toString(
          "0 " + rule.ScheduleExpression.substring(5, rule.ScheduleExpression.length - 1),
          { locale: "en", use24HourTimeFormat: true }
        ) + " (UTC)   "}
        {this.getRuleBadgeStatus(rule.State)}
      </li>
    ));
    return <ul>{listRules}</ul>;
  };

  getRuleBadgeStatus = (status) => {
    let variant = "";
    if (status === "ENABLED") {
      variant = "success";
    } else {
      variant = "secondary";
    }
    return (
      <Badge pill variant={variant}>
        {status}
      </Badge>
    );
  };

  showDiagram = (sfn) => {
    this.setState({ sfnDiagram: sfn, showDiagram: true });
  };

  hideDiagram = () => {
    this.setState({ showDiagram: false });
  };

  showStatistics = (sfn) => {
    this.setState({ sfnStatistics: sfn, showStatistics: true });
  };

  hideStatistics = () => {
    this.setState({ showStatistics: false });
  };

  showExecute = (sfn) => {
    this.setState({
      executingError: false,
      sfnExecute: sfn,
      showExecute: true,
    });
  };

  hideExecute = () => {
    this.setState({ executingError: false, showExecute: false });
  };

  submitExecute = async (e) => {
    e.preventDefault();
    this.setState({ executingError: false, executing: true });
    let inputBody = this.state.executeInput.trim();
    if (inputBody.length > 0) {
      inputBody = JSON.parse(inputBody);
    }
    await API.post("sfn", "/sfn/" + this.state.sfnExecute.stateMachineArn + "/exec", {
      body: inputBody,
    })
      .then((data) => {
        this.setState({ showExecute: false });
      })
      .catch((err) => {
        this.setState({ executingError: true, executingErrorMsg: err.message });
      });
    this.setState({ executing: false });
  };

  getTableHeader = () => {
    let header = [
      {
        dataField: "name",
        text: "Name",
        filter: selectFilter({
          options: this.state.nameFilterOptions,
        }),
        headerStyle: { verticalAlign: "middle" },
        style: { verticalAlign: "middle" },
      },
      {
        dataField: "stateMachineArn",
        text: "ARN",
        headerStyle: { verticalAlign: "middle" },
        style: { verticalAlign: "middle" },
      },
      {
        dataField: "events",
        text: "Event rules",
        headerStyle: { verticalAlign: "middle" },
        style: { verticalAlign: "middle" },
      },
      {
        dataField: "actions",
        text: "Actions",
        headerStyle: { verticalAlign: "middle" },
        style: { verticalAlign: "middle" },
      },
    ];
    return header;
  };

  getSfnList = () => {
    const listItems = this.state.sfnList.map((item, value) => {
      let data = {
        name: item.name,
        stateMachineArn: item.stateMachineArn,
        events: this.getEventRules(item),
        actions: (
          <>
            <Button
              variant="primary"
              size="sm"
              onClick={() => this.showDiagram(item)}
            >
              Diagram
            </Button>
            <Button
              variant="info"
              size="sm"
              onClick={() => this.showStatistics(item)}
            >
              Statistics
            </Button>
            <Button
              variant="warning"
              size="sm"
              onClick={() => this.showExecute(item)}
              disabled={!this.context.isAdmin}
            >
              Execute
            </Button>
          </>
        ),
      };
      return data;
    });
    return listItems;
  };

  render() {
    return (
      <div className="Workflows">
        <h2>Workflows</h2>
        {this.state.isReady && (
          <BootstrapTable
            bootstrap4
            keyField="name"
            data={this.getSfnList()}
            columns={this.getTableHeader()}
            pagination={paginationFactory({
              showTotal: true,
              sizePerPageList: [
                { text: "5", value: 5 },
                { text: "10", value: 10 },
                { text: "25", value: 25 },
                { text: "50", value: 50 },
              ],
            })}
            filter={filterFactory()}
            filterPosition="top"
            striped
            hover
            condensed
            noDataIndication="Table is Empty"
          />
        )}
        {!this.state.isReady && (
          <div className="d-flex justify-content-center">
            <Image src="/Loading_icon.gif"/>
          </div>
        )}
        {/* MODAL CON EL DIAGRAMA DEL PROCESO */}
        <Modal show={this.state.showDiagram} size="lg">
          <Modal.Header>
            <Modal.Title>{this.state.sfnDiagram.name} - Workflow definition</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="svg"
              dangerouslySetInnerHTML={{ __html: this.state.diagram }}
            ></div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.hideDiagram()}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* MODAL CON LaS ESTADISTICAS DEL PROCESO */}
        <Modal show={this.state.showStatistics} dialogClassName="stats">
          <Modal.Header>
            <Modal.Title>{this.state.sfnStatistics.name} - Execution statistics</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!this.state.isUpdating && (
              <ComposedChart
                width={1000}
                height={400}
                data={this.state.sfnStatisticsData}
                margin={{
                  top: 0,
                  left: 50,
                  right: 50,
                  bottom: 50,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  angle={-45}
                  interval={0}
                  textAnchor="end"
                />
                <YAxis
                  yAxisId="left"
                  orientation="left"
                  label={{
                    value: "Executions",
                    angle: -90,
                    position: "insideLeft",
                  }}
                />
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  label={{
                    value: "Duration",
                    angle: 90,
                    position: "insideRight",
                  }}
                  unit="s"
                />
                <Tooltip />
                <Legend verticalAlign="top" height={36} />
                {/* <Bar dataKey="TOTAL" yAxisId="left" stackId="a" fill="#bdbdbd" /> */}
                <Bar
                  dataKey="RUNNING"
                  name="Running"
                  yAxisId="left"
                  stackId="b"
                  fill="#3390ff"
                />
                <Bar
                  dataKey="SUCCEEDED"
                  name="Succeeded"
                  yAxisId="left"
                  stackId="b"
                  fill="#50cf14"
                />
                <Bar
                  dataKey="FAILED"
                  name="Failed"
                  yAxisId="left"
                  stackId="b"
                  fill="#ff3333"
                />
                <Bar
                  dataKey="CANCELLED"
                  name="Cancelled"
                  yAxisId="left"
                  stackId="b"
                  fill="#eecc4a"
                />
                <Line
                  dataKey="MIN"
                  name="Minimum duration"
                  yAxisId="right"
                  type="monotone"
                  stroke="#808000"
                  fill="#808000"
                  strokeDasharray="8 8"
                />
                <Line
                  dataKey="MED"
                  name="Medium duration"
                  yAxisId="right"
                  type="monotone"
                  stroke="#800080"
                  fill="#800080"
                  strokeDasharray="5 5"
                />
                <Line
                  dataKey="MAX"
                  name="Maximum duration"
                  yAxisId="right"
                  type="monotone"
                  stroke="#000000"
                  fill="#000000"
                  strokeDasharray="2 2"
                />
              </ComposedChart>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.hideStatistics()}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* MODAL CON EL FORMULARIO DE EJECUCION DEL PROCESO */}
        <Modal show={this.state.showExecute} size="lg">
          <Form onSubmit={(e) => this.submitExecute(e)}>
            <Modal.Header>
              <Modal.Title>{this.state.sfnExecute.name} - Execute workflow</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group>
                <Form.Label>Input JSON</Form.Label>
                <Form.Control
                  id="jsonInput"
                  as="textarea"
                  placeholder="Enter input"
                  value={this.state.executeInput}
                  onChange={(e) =>
                    this.setState({ executeInput: e.target.value })
                  }
                />
                <Form.Text className="text-muted">
                  Input in JSON format for execution (can be empty)
                </Form.Text>
              </Form.Group>
              <Alert variant="danger" show={this.state.executingError}>
                {this.state.executingErrorMsg}
              </Alert>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.hideExecute()}>
                Close
              </Button>
              <Button
                variant="danger"
                type="submit"
                disabled={this.state.executing}
              >
                {this.state.executing && <Spinner animation="border" />} Execute
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}
Workflows.contextType = AppContext;

export default Workflows;
